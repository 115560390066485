@import '../../../helpers/componentHeader';

.bannerwithrighttextoverlay {
    &__container {
        @include media-breakpoint-down(md) {
            padding:0px;
        }
    }
    &__wrapper {
        position: relative;
        height: 450px;
    }
    &__img {
        height: 100%;
        .banneroverlay-image {
            height: 100%;
            margin: 0px;
            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                display: block;
            }
        }
    }
    &__text {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.36);
        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
        @include media-breakpoint-up(lg) {
            background: linear-gradient(90deg, transparent 20%, rgba(0,0,0,.9));
        }
        .text {
            &-container {
                padding: 20px;
                text-align: center;
                @include media-breakpoint-up(md) {
                    width: 90%;
                    text-align: right;
                    padding: 20px 80px 20px 0px;
                }
                @include media-breakpoint-up(lg) {
                    width: 62%;
                    padding: 20px 60px 20px 0px;
                }
                @include media-breakpoint-up(xl) {
                    width: 55%;
                    padding: 20px 60px 20px 0px;
                }
            }
            &-title {
                font-family: $secondary-font-Regular;
                font-size: 18px;
                line-height: 32px;
                font-weight: 600;
                color: $white;
                letter-spacing: 2px;
                margin: 0px;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
                @include media-breakpoint-up(md) {
                    font-size: 39px;
                    line-height: 59px;
                }
            }
            &-description {
                font-family: $primary-font;
                font-size: 14px;
                line-height: 26px;
                color: $zircon;
                padding: 20px 0px 0px 0px;
                @include media-breakpoint-up(md) {
                    font-size: 16px;
                    line-height: 32px;
                    padding: 20px 0px 0px 20px;
                }
            }
            &-rfi {
                padding-top: 30px;
                @include media-breakpoint-up(lg) {
                    padding-top: 35px;
                }
            }
        }
    }
}